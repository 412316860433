<template>
  <div>
    <div class="lg:hidden">
      <div
        class="px-6 py-2 flex flex-col font-semibold lg:mt-32 lg:min-h-screen"
      >
        <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
          <button @click="$router.go(-1)">
            <LeftArrowIcon class="h-6 fill-current" />
          </button>
          <h2 class="mx-auto">FAQs</h2>
        </header>
        <BPageLoader v-if="isLoading" class="flex-grow" />
        <ul class="text-sm" v-else>
          <li
            class="my-6 py-1 flex items-center"
            v-for="({ id, question }, index) in data"
            :key="id"
          >
            <router-link
              class="flex-grow text-text-alternate-1"
              :to="{ name: 'FAQDetails', params: { slug: getSlug(index) } }"
              >{{ question }}</router-link
            >
            <router-link
              :to="{ name: 'FAQDetails', params: { slug: getSlug(index) } }"
            >
              <ChevronDownIcon class="h-6 fill-current transform -rotate-90" />
            </router-link>
          </li>
        </ul>
      </div>
    </div>
    <div class="hidden lg:flex justify-center pt-4">
      <div class="b-card mt-24 pt-8">
        <div class="px-26 py-2 flex flex-col font-semibold">
          <header class="py-2 text-lg flex text-text-alternate-1 pr-6">
            <button @click="$router.go(-1)">
              <LeftArrowIcon class="h-6 fill-current" />
            </button>
            <h2 class="mx-auto">FAQs</h2>
          </header>
          <BPageLoader v-if="isLoading" class="flex-grow" />
          <ul class="text-sm" v-else>
            <li
              class="my-6 py-1 flex items-center"
              v-for="({ id, question }, index) in data"
              :key="id"
            >
              <router-link
                class="flex-grow text-text-alternate-1"
                :to="{ name: 'FAQDetails', params: { slug: getSlug(index) } }"
                >{{ question }}</router-link
              >
              <router-link
                :to="{ name: 'FAQDetails', params: { slug: getSlug(index) } }"
              >
                <ChevronDownIcon
                  class="h-6 fill-current transform -rotate-90"
                />
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import { useStore } from 'vuex';
import { useAppState } from '@/cmp-functions/appState.js';

export default {
  name: 'FAQs',

  setup() {
    const store = useStore();
    const authUser = store.state.auth.authData;
    const { faqs } = useAppState();

    const getSlug = (index) => {
      const { id, question } = faqs.data[index];
      return `${id}-${question.toLowerCase().replace(/\s+/g, '-')}`;
    };

    return { authUser, ...toRefs(faqs), getSlug };
  },
};
</script>
